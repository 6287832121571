import React, { Component } from "react";
 
class Contact extends Component {
  render() {
    return (
      <div>
        <h2>¿Quieres unirte?</h2>
        <p>Contacta con Joni Vallés a través de  <a href="https://web.telegram.org/#/im?p=@JoniValles">Telegram</a>.
        </p>
      </div>
    );
  }
}
 
export default Contact;
