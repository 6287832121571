import React, { Component } from "react";
 
class Home extends Component {
  render() {
    return (
      <div>
        <h2>¿Qué es PokeMapGo! ?</h2>
        <p>Es un radar en vivo que muestra todos los Pokemon 100%, #1 en PvP, misiones, incursiones, invasiones del Team Rocket y muchisímo más. Si necesitas más información o estás interesad@ en unirte, ponte en contacto a través de la pestaña "Contacto".</p>
      </div>
    );
  }
}
 
export default Home;
