// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #FFCC00;
  padding: 20px;
  margin: 0;
}
h1, h2, p, ul, li {
  font-family: sans-serif;
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  background-color: #111;
  padding: 0;
}
ul.header li a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}
.content {
  background-color: #FFF;
  padding: 20px;
overflow-y: hidden;
}
.content h2 {
  padding: 0;
  margin: 0;
}
.content li {
  margin-bottom: 10px;
}
/* Blue links  */
.active {
  background-color: #0099FF;
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,SAAS;AACX;AACA;EACE,uBAAuB;AACzB;AACA;EACE,eAAe;EACf,qBAAqB;EACrB,SAAS;AACX;AACA;EACE,sBAAsB;EACtB,UAAU;AACZ;AACA;EACE,WAAW;EACX,iBAAiB;EACjB,qBAAqB;EACrB,aAAa;EACb,qBAAqB;AACvB;AACA;EACE,sBAAsB;EACtB,aAAa;AACf,kBAAkB;AAClB;AACA;EACE,UAAU;EACV,SAAS;AACX;AACA;EACE,mBAAmB;AACrB;AACA,gBAAgB;AAChB;EACE,yBAAyB;AAC3B","sourcesContent":["body {\n  background-color: #FFCC00;\n  padding: 20px;\n  margin: 0;\n}\nh1, h2, p, ul, li {\n  font-family: sans-serif;\n}\nul.header li {\n  display: inline;\n  list-style-type: none;\n  margin: 0;\n}\nul.header {\n  background-color: #111;\n  padding: 0;\n}\nul.header li a {\n  color: #FFF;\n  font-weight: bold;\n  text-decoration: none;\n  padding: 20px;\n  display: inline-block;\n}\n.content {\n  background-color: #FFF;\n  padding: 20px;\noverflow-y: hidden;\n}\n.content h2 {\n  padding: 0;\n  margin: 0;\n}\n.content li {\n  margin-bottom: 10px;\n}\n/* Blue links  */\n.active {\n  background-color: #0099FF;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
