import React, { Component } from "react";
import gible from './gible.PNG';
import mapa from './pk.PNG';
import bidoof from './bidoof.PNG';
class Stuff extends Component {
  render() {
    return (
      <div>
	<h2>Notificaciones</h2>
<p style={{paddingTop: '1em'}}>Notificaciones personalizadas de todos los Pokemon, misiones, invasiones de Team Rocket e incursiones que te interesen. </p>
<img src={gible} width="600px" height="400px" />

        <p style={{paddingTop: '8em'}}><h2>Mapa</h2></p>
        <p style={{paddingTop: '1em'}}>Mapa en vivo donde se podrá ver a tiempo real todo lo anterior mencionado y mucho más!</p>
          <img src={mapa} width="2400px" height="1600px" />
          <img src={bidoof} width="2400px" height="1600px" />
      </div>
    );
  }
}
 
export default Stuff;
