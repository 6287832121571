import React, { Component } from "react";
import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";
import Home from "./Home";
import Stuff from "./Stuff";
import Contact from "./Contact";
import Logo from "./circle-logo.png";

class Main extends Component {
  render() {
    return (
<HashRouter>
        <div>
          <h1><img src={Logo} width="45px" height="45px" /> PokeMapGo! </h1> 

          <ul className="header">
            <li><NavLink to="/">Inicio</NavLink></li>
            <li><NavLink to="/stuff">Radar</NavLink></li>
            <li><NavLink to="/contact">Contacto</NavLink></li>
          </ul>
          <div className="content">
            <Route exact path="/" component={Home}/>
            <Route path="/stuff" component={Stuff}/>
            <Route path="/contact" component={Contact}/>
             
          </div>
        </div>
</HashRouter>
    );
  }
}
 
export default Main;
